@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  font-family: "Poppins", sans-serif;
}

:root:has(.no-scroll) {
  overflow: hidden;
}

* {
  scrollbar-width: thin;
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
}

/* Carousel styles */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes scrollTech {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20%);
  }
}

.tech-carousel {
  animation: scrollTech 10s linear infinite alternate;
}

.tech-carousel:hover {
  animation-play-state: paused;
}
